<template>
  <f7-page no-navbar>

    <img
      class="imgBox"
      src="../assets/header.jpg"
    />

    <div class="list media-list">
      <ul>
        <li>
          <a
            href="/cmbBank/"
            class="item-link item-content"
          >
            <div
              class="item-media"
              style="align-self: center;"
            >
              <img
                src="../assets/logo/cmslogo.jpg"
                width="80"
              />
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">闪电贷</div>
              </div>
              <div class="item-subtitle">3-4月利率优惠至最低<span style="font-weight: bold;">4.05%</span>起</div>
              <div class="item-text" style="padding-top: 5px;">线上申请最高20万</div>
              <div class="item-text">线下可提额至30万</div>
              <div class="item-text">单笔最长贷款期限3年</div>
            </div>
          </a>
        </li>

        <li>
          <a
            href="/citicBank/"
            class="item-link item-content"
          >
            <div
              class="item-media"
              style="align-self: center;"
            >
              <img
                src="../assets/logo/中信银行logo.jpg"
                width="80"
              />
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">信秒贷</div>
              </div>
              <div class="item-subtitle">3月利率优惠至最低<span class="text-red-bold">3.85%</span>起</div>
              <div class="item-text" style="padding-top: 5px;">线上申请最高20万</div>
              <div class="item-text">线下可提额至30万</div>
              <div class="item-text">单笔借款期限最长1年</div>
            </div>
          </a>
        </li>

        <!-- <li>
          <a
            href="/msBank/"
            class="item-link item-content"
          >
            <div
              class="item-media"
              style="align-self: center;"
            >
              <img
                src="../assets/logo/mslogo.jpg"
                width="80"
              />
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">民易贷</div>
              </div>
              <div class="item-subtitle">3月利率优惠至最低<span class="text-red-bold">4.35%</span>起</div>
              <div class="item-text" style="padding-top: 5px;">线上申请最高20万</div>
              <div class="item-text">线下可提额至30万</div>
              <div class="item-text">单笔借款期限最长1年</div>
            </div>
          </a>
        </li> -->

        <li>
          <a
            href="/gfBank/"
            class="item-link item-content"
          >
            <div
              class="item-media"
              style="align-self: center;"
            >
              <img
                src="../assets/logo/gf.jpg"
                width="80"
              />
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">E秒贷</div>
              </div>
              <div class="item-subtitle">目前利率优惠至最低<span class="text-red-bold">4.8%</span>起</div>
              <div class="item-text" style="padding-top: 5px;">线上申请最高20万</div>
              <div class="item-text">线下可提额至30万</div>
              <div class="item-text">额度期限1年，单笔最长贷款期限3年</div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <!-- <f7-block style="padding-bottom: 10px;"> -->
    <div style="padding-top: 40px; padding-bottom: 15px;">
      <f7-block-header>资金来源:贷款资金将由正规持牌金融机构提供</f7-block-header>
      <f7-block-header>版权所有：东航商业保理有限公司</f7-block-header>
      <f7-block-header>贷款有风险，选择需谨慎</f7-block-header>
      <f7-block-header>请根据个人能力合理贷款，理性消费，避免逾期</f7-block-header>
    </div>
    <!-- </f7-block> -->
  </f7-page>
</template>


<style lang="less" scoped>
.imgBox {
  width: 100%;
  height: 25%;
}
.block-title {
  text-align: center;
}
.block-header {
  text-align: center;
  margin-bottom: 0;
  font-size: 10px;
  --f7-block-margin-vertical: 0px;
}
.list {
  --f7-list-margin-vertical: 0;
}
.list .item-subtitle {
  white-space: break-spaces;
}
.text-red-bold {
  // color: red;
  font-weight: bold;
}
.item-text {
  --f7-list-item-text-font-size: 14px;
}
// .navbar {
//   --f7-navbar-height: 0px;
// }
</style>